var exports = {};

function dupe_array(count, value, i) {
  var c = count[i] | 0;

  if (c <= 0) {
    return [];
  }

  var result = new Array(c),
      j;

  if (i === count.length - 1) {
    for (j = 0; j < c; ++j) {
      result[j] = value;
    }
  } else {
    for (j = 0; j < c; ++j) {
      result[j] = dupe_array(count, value, i + 1);
    }
  }

  return result;
}

function dupe_number(count, value) {
  var result, i;
  result = new Array(count);

  for (i = 0; i < count; ++i) {
    result[i] = value;
  }

  return result;
}

function dupe(count, value) {
  if (typeof value === "undefined") {
    value = 0;
  }

  switch (typeof count) {
    case "number":
      if (count > 0) {
        return dupe_number(count | 0, value);
      }

      break;

    case "object":
      if (typeof count.length === "number") {
        return dupe_array(count, value, 0);
      }

      break;
  }

  return [];
}

exports = dupe;
export default exports;